function getNextIndex(indexGroup){
  if(!window.hasOwnProperty('dashboardFormsIndexes')){
    window.dashboardFormsIndexes = {};
  }

  if(!window.dashboardFormsIndexes.hasOwnProperty(indexGroup)){
    window.dashboardFormsIndexes[indexGroup] = 0;
  } else {
    window.dashboardFormsIndexes[indexGroup]++;
  }

  return indexGroup + "_" + window.dashboardFormsIndexes[indexGroup];
}

function bindRowEvetns($parent) {
  var sGenerateIndexes = $parent.data('generateindexes');

  if(sGenerateIndexes) {
    var aGenerateIndexes = sGenerateIndexes.split(',');
    for (var i = 0; i < aGenerateIndexes.length; i++) {
      var indexesGroup = aGenerateIndexes[i];
      var nextIndex = getNextIndex(indexesGroup);
      var $inputs = $parent.find('[data-name*="' +  '[' + indexesGroup + ']"]');
      for (var ii = 0; ii < $inputs.length; ii++) {
        var name = $inputs.eq(ii).attr('name');

        if(!name) {
          var name = $inputs.eq(ii).attr('data-name');
        }

        while(name.indexOf('[' + indexesGroup + ']') >= 0){
          name = name.replace('[' + indexesGroup + ']', '[' + nextIndex + ']');
        }

        $inputs.eq(ii).attr('name', name);
      }
    }
  }

  $parent.find('.color-input').spectrum({
    showInput: true,
    preferredFormat: "hex"
  });
  $parent.find('.remove-row').click(removerow);
  $parent.find('input:checkbox').switcher({
    language: 'de',
    copy: {
      de: {
        yes: 'ja',
        no: 'nein'
      }
    }
  });
}

function createNewRow() {
  var $this = $(this);
  var $table = $this.closest('table');
  var $a = $table.find('tr.hidden.pattern').clone();
  $a.removeClass('hidden');
  $a.removeClass('pattern');
  $a.addClass('interval');
  var $tbody = $table.find('tbody').parent();
  $a.appendTo($tbody);
  $a.show();
  if (typeof (bindRowEvetns) !== 'undefined') {
    bindRowEvetns($a);
  }
}

function getquestiontext(textid) {
  if ($('#' + textid).length > 0) {
    return $('#' + textid).val();
  }
  return '';
}

function removerow() {
  if (confirm(getquestiontext('removerowmessage'))) {
    $this = $(this);
    $this.closest('tr').remove();
  }
}

if(typeof(getChartSettings) === 'undefined') {
  function getChartSettings() {
    return {
      fill: false,
      lineTension: 0,
      displayLegend: false,
      displayAxesX: true,
      displayAxesY: true,
      widthAxesY: 65,
      responsive: true,
      maintainAspectRatio: false,
      type: "line"
    };
  }
}

function generateUniqueId(){
  var idPrefix = "generated";
  var idIndex = 0;
  while($('#' + idPrefix + idIndex).length !== 0){
    idIndex++;
  }
  return idPrefix + idIndex;
}

function showChart($canvas) {

  if(!$canvas.attr('id')){
    $canvas.attr('id', generateUniqueId());
  }

  var chartData = $canvas.data('chatdata');
  if (!chartData) var chartData = {};
  var chartLabel = $canvas.data('chartlabel');
  if (!chartLabel) var chartLabel = 'Umsatz';
  var borderColor = $canvas.data('bordercolor');
  if (!borderColor) var borderColor = "#ff0000";


  var labels = [];
  var values = [];

  for (var i in chartData) {
    labels.push(chartData[i].label);
    values.push(chartData[i].value);
  }

  var chartdata = {
    keepShowing: [],
    labels: labels,
    datasets: [{
      label: chartLabel,
      borderColor: borderColor,
      lineTension: getChartSettings()["lineTension"],
      fill: getChartSettings()["fill"],
      data: values,
    }]
  };

  var chart = new Chart($canvas, {
    options: {
      showAllTooltips: false,
      legend: {
        display: getChartSettings()["displayLegend"]
      },
      scales: {
        xAxes: [{
          display: getChartSettings()["displayAxesX"]
        }],
        yAxes: [{
          display: getChartSettings()["displayAxesY"],
          afterFit: function (scaleInstance) {
            scaleInstance.width = getChartSettings()["widthAxesY"];
          }
        }]
      },
      tooltips: {
        callbacks: {
          label: function (t, d) {
            if (t.datasetIndex === 0) {
              var strArr = [];

              strArr.push(number_format(t.yLabel, 2, ',', '.') + ' €');

              return strArr;
            }
          },
          title: function () {
          }
        }
      },
      responsive: getChartSettings()["responsive"],
      maintainAspectRatio: getChartSettings()["maintainAspectRatio"],
      /*onClick: function (evt) {
        var activeElement = this.getElementAtEvent(evt);

        if (activeElement.length > 0) {
          var keepShowing = this.data.keepShowing;

          if (keepShowing.includes(activeElement[0]._index)) {
            var index = keepShowing.indexOf(activeElement[0]._index);
            keepShowing.splice(index, 1);
          } else {
            keepShowing.push(activeElement[0]._index);
          }
        }
      }.bind(this),*/
    },
    plugins: [{
      beforeRender: function (c) {
        var datasets = chartdata.datasets;
        c.pluginTooltips = [];
        var abscissaToShow = chartdata.keepShowing;
        abscissaToShow.forEach(function (element) {
          var activeArray = [];

          for (i = 0; i < datasets.length; i++) {
            if (!c.getDatasetMeta(i).hidden) activeArray.push(c.getDatasetMeta(i).data[element])
          }

          c.pluginTooltips.push(new Chart.Tooltip({
            _chart: c.chart,
            _chartInstance: c,
            _data: c.data,
            _options: c.options.tooltips,
            _active: activeArray
          }, c));
        });

        if (c.config.options.showAllTooltips) {
          c.pluginTooltips = [];
          c.config.data.datasets.forEach(function (dataset, i) {
            c.getDatasetMeta(i).data.forEach(function (sector, j) {
              c.pluginTooltips.push(new Chart.Tooltip({
                _chart: c.chart,
                _chartInstance: c,
                _data: c.data,
                _options: c.options.tooltips,
                _active: [sector]
              }, c));
            });
          });

          c.options.tooltips.enabled = false;
        }
      },
      afterDraw: function (c, easing) {
        if (c.config.options.showAllTooltips) {
          if (!c.allToolltipsOnce) {
            if (easing !== 1) {
              return;
            }

            c.allToolltipsOnce = true;
          }

          c.options.tooltips.enabled = true;

          Chart.helpers.each(c.pluginTooltips, function (tooltip) {
            tooltip.initialize();
            tooltip.update();
            tooltip.pivot();
            tooltip.transition(easing).draw();
          });

          c.options.tooltips.enabled = false;
        }
      },
      afterDatasetsDraw: function (c, easing) {
        Chart.helpers.each(c.pluginTooltips, function (tooltip) {
          tooltip.initialize();
          tooltip.update();
          tooltip.pivot();
          tooltip.transition(easing).draw();
        });
      }
    }],
    type: getChartSettings()["type"],
    data: chartdata
  });
}


$(document).ready(function () {
  $('[data-toggle="tooltip"]').tooltipster({
    contentCloning: true,
    theme: 'tooltipster-light',
  });

  $('.create-row').click(createNewRow);
  $('table .ui-sortable-handle').sortable();

  $('canvas.chart').each(function(index){
    var $canvas = $('canvas.chart').eq(index);
    showChart($canvas);
  });

  $('.sameheight').each(function (index) {
    var $current = $('.sameheight').eq(index);
    var sameToClasses = $current.data('sameheight');
    var aSameToClasses = sameToClasses.split(',');
    var max = $current.height();
    if (aSameToClasses.length > 0) {
      for (var i = 0; i < aSameToClasses.length; i++) {
        $compareTo = $('.' + aSameToClasses[i]);
        if ($compareTo.length && max < $compareTo.height()) {
          max = $compareTo.height();
        }
      }
    }
    $current.height(max);
  });

  $('.show-wiki').click(function(){

  });
});